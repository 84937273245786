import React from 'react';

// Bootstrap
import {Container, Col, Row} from 'react-bootstrap';

// Less
import './EnterpriseServices.less';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const EnterpriseServices = ({data, title}) => {
  const enterprise = data.map(({node}, key) => (
    <Col className="enterprise__card" sm={6} key={key}>
      <Row>
        <Col className="enterprise__image" xs={2}>
          <img src={createAssetUrl(node.icon)} alt={node.title} />
        </Col>
        <Col xs={10}>
          <h3>{node.title}</h3>
          <p>{node.description}</p>
        </Col>
      </Row>
    </Col>
  ));
  return (
    <div className="enterprise">
      <Container>
        <h2 className="enterprise__title">{title}</h2>
        <Row>{enterprise}</Row>
      </Container>
    </div>
  );
};

export default EnterpriseServices;
