import React from 'react';

// Layout
import Layout from '../../layout/Layout';

// Components
import Banner from '../../../components/common/banner/Banner';
import TextWidget from '../../../components/common/text-widget/TextWidget';
import DeliverServices from '../../../components/common/services/DeliverServices';
import EnterpriseServices from '../../../components/services/enterprise-services/EnterpriseServices';
import TextImage from '../../../components/common/text-image/TextImage';
import Portfolio from '../../../components/common/portfolio/Portfolio';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const ServicesDetails = ({
  pageContext: {page, textWidget, aemServices, enterpriseServices},
}) => {
  const bannerOpacity = {
    opacity: '0.75',
  };

  const bannerOverlay = {
    backgroundImage: `url(${createAssetUrl(page.bannerImg)})`,
  };

  const textWidgetClasses =
    page.slug === 'aem/assets'
      ? `text-widget--assets`
      : `text-widget--${page.slug}`;

  return (
    <Layout>
      <Banner
        bannerOpacity={bannerOpacity}
        bannerOverlay={bannerOverlay}
        text={page.bannerSubtext}
        className="bannerSecondary"
      >
        {page.bannerText}
      </Banner>
      {textWidget && (
        <TextWidget data={textWidget} className={textWidgetClasses} />
      )}
      {aemServices && (
        <DeliverServices className="services--aem" data={aemServices} />
      )}
      {enterpriseServices && (
        <EnterpriseServices
          data={enterpriseServices}
          title="We offer cloud and enterprise services in:"
        />
      )}
      {page.textImage &&
        page.textImage.map((item, index) => (
          <TextImage
            key={index}
            image={item.image}
            header={item.header}
            headerOnCenter={item.headerOnCenter}
            headerIcon={item.headerIcon}
            logo={item.logo}
            client={item.client}
            paragraph={item.paragraph}
            imageOnLeft={item.imageOnLeft}
            imageOnBottom={item.imageOnBottom}
            logoOnCenter={item.logoOnCenter}
            backgroundColor={item.backgroundColor}
            imageWidth={item.imageWidth}
            logoWidth={item.logoWidth}
          />
        ))}
      {page.relatedProjects && (
        <Portfolio
          title={page.relatedProjects.title}
          type={page.relatedProjects.type}
          portfolioClass={page.relatedProjects.className}
        />
      )}
    </Layout>
  );
};

export default ServicesDetails;
