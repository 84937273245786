import React from 'react';

// Less
import './TextImage.less';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const TextImage = ({
  image,
  logo,
  header,
  headerIcon,
  paragraph,
  client,
  imageOnLeft,
  imageOnBottom,
  logoOnCenter,
  headerOnCenter,
  backgroundColor,
  imageWidth,
  logoWidth,
}) => {
  const paragraphs =
    paragraph &&
    paragraph.map((p, index) => (
      <p className="text-image__paragraph" key={index}>
        {p}
      </p>
    ));

  return (
    <div className="text-image" style={{backgroundColor}}>
      <Container>
        {imageOnBottom ? (
          <div className="text-image--imageOnBottom">
            {header && (
              <h2
                className={headerOnCenter && 'text-image__header-icon-centered'}
              >
                &nbsp;
                {header}
              </h2>
            )}
            {paragraphs && paragraphs}
            <img
              src={createAssetUrl(image)}
              alt="client website"
              className="text-image__image-onBottom img-responsive"
              width={imageWidth && imageWidth}
            />
          </div>
        ) : (
          <Row
            className={
              imageOnLeft
                ? 'text-image__widget text-image__widget--imageOnLeft'
                : 'text-image__widget text-image__widget--imageOnRight'
            }
          >
            {imageOnLeft ? (
              <>
                <Col className="text-image__image" lg={6}>
                  <img
                    src={createAssetUrl(image)}
                    alt="client website"
                    className="text-image__image-onLeft img-responsive"
                    width={imageWidth && imageWidth}
                  />
                </Col>
                <Col className="text-image__text" lg={6}>
                  {logo && (
                    <div
                      className={
                        logoOnCenter
                          ? 'text-image__logo-centered'
                          : 'text-image__logo'
                      }
                    >
                      <img
                        src={createAssetUrl(logo)}
                        alt="logo"
                        width={logoWidth && logoWidth}
                      />
                    </div>
                  )}
                  {client && (
                    <h3 className="text-image__client">{`Client: ${client}`}</h3>
                  )}
                  {header && (
                    <h2
                      className={
                        headerOnCenter && 'text-image__header-icon-centered'
                      }
                    >
                      {headerIcon && <i className={headerIcon} />}
                      &nbsp;
                      {header}
                    </h2>
                  )}
                  {paragraphs && paragraphs}
                </Col>
              </>
            ) : (
              <>
                <Col className="text-image__text" lg={6}>
                  {logo && (
                    <div
                      className={
                        logoOnCenter
                          ? 'text-image__logo-centered'
                          : 'text-image__logo'
                      }
                    >
                      <img
                        src={createAssetUrl(logo)}
                        alt="logo"
                        width={logoWidth && logoWidth}
                      />
                    </div>
                  )}
                  {client && (
                    <h3 className="text-image__client">{`Client: ${client}`}</h3>
                  )}
                  {header && (
                    <h2
                      className={
                        headerOnCenter && 'text-image__header-icon-centered'
                      }
                    >
                      {headerIcon && <i className={headerIcon} />}
                      &nbsp;
                      {header}
                    </h2>
                  )}
                  {paragraphs && paragraphs}
                </Col>
                <Col className="text-image__image" lg={6}>
                  <img
                    src={createAssetUrl(image)}
                    alt="client website"
                    className="text-image__image-onRight img-responsive"
                    width={imageWidth && imageWidth}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default TextImage;
